
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TextBlock from "../components/text-block";

function CheatsheetTemplate({ location, data }) {

  // TODO: can we use this format for cheatsheets? https://developers.google.com/search/docs/advanced/structured-data/education-qa

  // TODO: add better cheatsheet description

  const cheatsheet = data.cheatsheetsYaml
  const images = data.allFile.edges.map(image => image.node)



  return (
    <Layout location={location}>
      <Seo
        title={cheatsheet.title}
        metaDescription={cheatsheet.title}
        metaElements={
          cheatsheet.filePrefix
            ? [
                {
                  name: "image",
                  property: "og:image",
                  content: findImage(
                    images,
                    cheatsheet.filePrefix,
                    "@thumb.png"
                  )?.publicURL,
                },
                {
                  name: "twitter:image",
                  content: findImage(
                    images,
                    cheatsheet.filePrefix,
                    "@thumb.png"
                  )?.publicURL,
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
              ]
            : []
        }
      />

      <section className="mt-8 mb-20 lg:mt-18">
        <header>
          <h1>{cheatsheet.title}</h1>
        </header>

        <div className="mt-11 flex flex-wrap md:mt-14">
          <img
            src={findImage(images, cheatsheet.filePrefix, "@2x.png")?.publicURL}
            className="w-full"
          />
        </div>

        <TextBlock contentList={cheatsheet}/>

        <a
          className="mt-10 flex max-h-22.5 flex-1 items-center justify-center bg-rose-400 p-9 text-2xl font-bold uppercase text-white duration-200 hover:bg-rose-500 xl:flex-none"
          href={findImage(images, cheatsheet.filePrefix, ".pdf")?.publicURL}
          download
        >
          Download
        </a>
      </section>
    </Layout>
  )
}

function findImage(images, prefix, suffix) {
  return images.find(image => image.base.startsWith(prefix) && image.base.endsWith(suffix))
}

export default CheatsheetTemplate

export const pageQuery = graphql`
  query (
    $id: String!
  ) {
    allFile(filter: {
      sourceInstanceName: {eq: "cheatsheets"}
      relativeDirectory: {eq: "images"}
    }
    ) {
      edges {
        node {
          id
          base
          publicURL
        }
      }
    }
    cheatsheetsYaml(id: { eq: $id }) {
      id
      slug
      filePrefix
      title
      content {
        key
        value
        values
      }
    }
  }
`
